<template>
  <div class="dashboard-wrap">

    <v-container class="dashboard-cards">
      <v-row>
        <v-col class="card-col" cols="12" md="6"
               order="1" order-md="1">
          <h2 class="mb-3">{{$t('dashboard.card-programs')}}</h2>
          <router-link :to="exercisesLocation" class="card-link">
            <v-sheet rounded="xl" class="MProMain5 card-sheet">
              <mpro-icon-exercises class="card-icon"/>
              <div v-if="singleActiveProgram != null" class="flex-grow-1">
                <mpro-exercise-program :program="singleActiveProgram"
                                       embedded/>
              </div>
              <span v-else-if="activeSubordinate == null">
                {{$tc('programs.num-active-programs-seeker', this.activePrograms.length)}}
              </span>
              <span v-else>
                {{$tc('programs.num-active-programs-name', this.activePrograms.length, { name: activeSubordinate.FirstName })}}
              </span>
            </v-sheet>
          </router-link>
        </v-col>
        <v-col class="card-col" cols="12" md="6"
               order="4" order-md="2">
          <h2 class="mb-3">{{$t('dashboard.card-goals')}}</h2>
          <router-link :to="{ name: 'MyGoals' }" class="card-link">
            <div class="p-relative h-100pc">
              <v-sheet rounded="xl" class="MProMain5 card-sheet">
                <mpro-icon-goals class="card-icon"/>
                <span v-if="openGoals.length === 0">{{$t('goals.none-active')}}</span>
                <span v-else-if="activeSubordinate == null">{{$t('goals.active-goals-seeker')}}</span>
                <span v-else>{{$t('goals.active-goals-subordinate', { name: activeSubordinate.FirstName })}}</span>
              </v-sheet>
              <v-sheet v-if="openGoals.length > 0"
                       color="MProCoral" rounded="xl" class="card-badge">
                {{openGoals.length}}
              </v-sheet>
            </div>
          </router-link>
        </v-col>
        <v-col class="card-col" cols="12" md="6"
               order="2" order-md="3">
          <h2 class="mb-3">{{$t('dashboard.card-scans')}}</h2>
          <router-link :to="lastScanLocation" class="card-link">
            <v-sheet rounded="xl" light class="card-sheet">
              <mpro-icon-scans class="card-icon"/>
              <div v-if="lastScan != null" class="flex-grow-1 d-flex flex-column align-stretch">
                <span class="v-card__subtitle pa-0">
                  {{
                    activeSubordinate == null
                      ? $t('dashboard.your_latest_scan')
                      : $t('dashboard.subordinate_latest_scan', { name: activeSubordinate.FirstName })
                  }}
                  |
                  {{$formatDate(lastScan.CapturedAt)}}
                </span>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    <div><b>{{$t('scans.scan-title', { num: scans.length })}}</b></div>
                    <div class="v-card__text pa-0">
                      <span v-if="lastScan.ScanType === '3D'">{{$t('scans.3d-scan')}}</span>
                      <span v-if="lastScan.ScanType === '2D'">{{$t('scans2d.mobile-scan')}}</span>
                    </div>
                  </div>
                  <v-icon class="ma-4">{{getScanIcon(lastScan).name}}</v-icon>
                </div>
              </div>
              <span v-else>{{$t('dashboard.no_scans_done')}}</span>
            </v-sheet>
          </router-link>
        </v-col>
        <v-col class="card-col" cols="12" md="6"
               order="3" order-md="4">
          <h2 class="mb-3">{{$t('dashboard.card-surveys')}}</h2>
          <router-link :to="{ name: 'MySurveys' }" class="card-link">
            <div class="p-relative h-100pc">
              <v-sheet rounded="xl" class="MProMain5 card-sheet">
                <mpro-icon-surveys class="card-icon"/>
                <span v-if="assignedSurveys.length === 0">
                  {{ $t('surveys.no-pending-surveys-seeker')}}
                </span>
                <span v-else-if="activeSubordinate == null">
                  {{$t('surveys.pending-surveys-seeker')}}
                </span>
                <span v-else>
                  {{$t('surveys.pending-surveys-subordinate', { name: activeSubordinate.FirstName })}}
                </span>
              </v-sheet>
              <v-sheet v-if="assignedSurveys.length > 0"
                       color="MProCoral" rounded="xl" class="card-badge">
                {{assignedSurveys.length}}
              </v-sheet>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>

    <div class="dashboard-side pa-4">

      <h3>{{dataAccessTitle}}</h3>
      <h2>{{dataAccessTitle}}</h2>
      <v-btn x-large color="MProGreen" class="my-2 rounded-pill"
             :to="{ name: 'MyAccount', query: { action: 'add-data-sharing' } }">
        <v-icon class="mr-2">mdi-playlist-plus</v-icon>
        <span>{{$t('dashboard.data-access-add')}}</span>
      </v-btn>
      <v-btn x-large color="MProWhite" class="my-2 rounded-pill"
             :to="{ name: 'MyAccount', query: { action: 'view-data-sharings' } }">
        <v-icon class="mr-2">mdi-hand-heart</v-icon>
        <span>{{$t('dashboard.data-access-view')}}</span>
      </v-btn>

      <h3 class="mt-4">{{$t('dashboard.get-scanned')}}</h3>
      <h2 class="mt-4">{{$t('dashboard.get-scanned')}}</h2>
      <mpro-create-scan-code>
        <template #button={on}>
          <v-btn x-large v-on="on" color="MProPurple" class="my-2 rounded-pill">
            <v-icon class="mr-2">mdi-qrcode</v-icon>
            <span>{{$t('account.create_scan_code.title')}}</span>
          </v-btn>
        </template>
      </mpro-create-scan-code>
      <v-btn x-large color="MProBlue" class="my-2 rounded-pill"
             href="https://play.google.com/store/apps/details?id=com.qinematic.moovmentpro"
             target="_blank">
        <v-icon class="mr-2">mdi-android</v-icon>
        <span>{{$t('dashboard.scan-android')}}</span>
      </v-btn>
      <v-btn x-large color="MProBlue" class="my-2 rounded-pill"
             href="https://apps.apple.com/app/moovment-pro/id1527755083"
             target="_blank">
        <v-icon class="mr-2">mdi-apple</v-icon>
        <span>{{$t('dashboard.scan-apple')}}</span>
      </v-btn>

    </div>

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Lo from 'lodash'
import ProgramAnalyzer from './exercises/program-analyzer'
import { getScanIcon } from '@/models/ui'
import IconExercises from '@/components/icons/Exercises.vue'
import IconGoals from '@/components/icons/Goals.vue'
import IconScans from '@/components/icons/Scans.vue'
import IconSurveys from '@/components/icons/Surveys.vue'
import CreateScanCode from '@/components/users/account/create-scan-code.vue'
import ExerciseProgram from '@/components/exercises/exercise_program.vue'

export default {
  components: {
    'mpro-icon-exercises': IconExercises,
    'mpro-icon-goals': IconGoals,
    'mpro-icon-scans': IconScans,
    'mpro-icon-surveys': IconSurveys,
    'mpro-create-scan-code': CreateScanCode,
    'mpro-exercise-program': ExerciseProgram
  },
  computed: {
    ...mapState('seeker', ['goals', 'scans', 'surveys']),
    ...mapState('seeker', {
      assignedSurveys: 'surveyAssignments',
      programs: 'exercisePrograms'
    }),
    ...mapGetters('exercises', ['getAssignedPrograms']),
    ...mapGetters('chats', ['getUnreadCount']),
    ...mapGetters('seeker', ['activeSubordinate']),
    activePrograms: function () {
      return this.programs.filter(ProgramAnalyzer.isActive)
    },
    singleActiveProgram: function () {
      if (this.activePrograms.length === 1) {
        const result = Lo(this.activePrograms)
          .orderBy(p => p.StartDate, 'desc')
          .first()
        result.progress = ProgramAnalyzer.getProgress(result)
        return result
      } else {
        return undefined
      }
    },
    exercisesLocation: function () {
      const location = { name: 'MyExercises' }
      if (this.singleActiveProgram != null) {
        location.query = { programId: this.singleActiveProgram.Id }
      }
      return location
    },
    lastScan: function () {
      return this.scans.length > 0 ? this.scans[0] : null
    },
    lastScanLocation: function () {
      if (this.lastScan == null) {
        return { name: 'MyScans' }
      } else {
        return {
          name: 'Scan',
          params: { scanId: this.lastScan.Id }
        }
      }
    },
    openGoals: function () {
      return this.goals.filter(s => !s.EndDate)
    },
    dataAccessTitle: function () {
      return this.activeSubordinate == null
        ? this.$t('dashboard.data-access')
        : this.$t('dashboard.data-access-subordinate', { name: this.activeSubordinate.FirstName })
    }
  },
  watch: {
    activeSubordinate: function () {
      this.ensureSeekerDataLoaded()
    }
  },
  methods: {
    ...mapActions('seeker', [
      'ensureScansLoaded', 'ensureGoalsLoaded', 'ensureExerciseProgramsLoaded',
      'ensureSurveysLoaded', 'ensureSurveyAssignmentsLoaded'
    ]),
    ...mapActions(['setHeaderTitle']),
    getScanIcon (scan) {
      return getScanIcon(scan)
    },
    ensureSeekerDataLoaded () {
      this.ensureScansLoaded()
      this.ensureExerciseProgramsLoaded()
      this.ensureGoalsLoaded()
      this.ensureSurveysLoaded()
      this.ensureSurveyAssignmentsLoaded()
    }
  },

  created () {
    this.ensureSeekerDataLoaded()
  },

  mounted () {
    this.setHeaderTitle()
  }
}
</script>

<style lang="scss" scoped>
@import 'src/scss/overrides.sass';

.dashboard-wrap {
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
}
.dashboard-cards {
  width: auto;
  max-width: 900px;
  .card-col {
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .card-sheet {
    height: 100%;
    padding: 16px 20px 16px 16px;
    display: flex;
    align-items: center;
  }
  .card-badge {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px 16px;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    text-align: center;
  }
  .card-icon {
    width: 72px;
    height: 72px;
    align-self: center;
    margin-right: 12px;
  }
  a.card-link {
    flex-grow: 1;
    text-decoration: none;
  }
}
.dashboard-side {
  align-self: stretch;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  .v-btn {
    justify-content: left;
    span {
      white-space: pre-line;
    }
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .dashboard-wrap {
    align-self: center;
    flex-direction: column;
  }
  .dashboard-cards {
    .card-col {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .dashboard-side {
    h3 {
      display: none;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .dashboard-cards {
    max-width: 450px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .dashboard-side {
    background-color: $mpro-dark5;
    h2 {
      display: none;
    }
    .v-btn span {
      font-size: 0.875rem;
    }
  }
}
</style>
